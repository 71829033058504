import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import { TextInput, MaskedInput, SelectInput, RadioButtonGroup, RadioInput } from "components/UI"
import CarBrandStore from "stores/CarBrandStore/List"
import CarModelStore from "stores/CarModelStore/List"
import { getIn } from "formik"
import qs from "qs"

const carBrandStore = CarBrandStore.create()
const carModelStore = CarModelStore.create()

const AutoTab = ({ store, ...props }) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const prolongation = params.prolongation == "true"

  useEffect(() => {
    carBrandStore.fetch({ limit: 0 })
    carModelStore.fetch({ limit: 0, filter: { by_car_brand_id: props.values.contract_auto.car_brand.value } })
  }, [])
  const carBrandOptions = carBrandStore.carBrandOptions
  const carModelOptions = carModelStore.carModelOptions
  const { contractAutoRisksOptions, contractAutoNumberKindsOptions } = store.filtersOptions

  const rusLetters = /(А|В|Е|К|М|Н|О|Р|С|Т|У|Х)/i
  const engLetters = /(D|T)/i

  const masks = {
    0: [rusLetters, /\d/, /\d/, /\d/, rusLetters, rusLetters, /\d/, /\d/, /\d/],
    1: [/\d/, /\d/, /\d/, /\d/, rusLetters, rusLetters, /\d/, /\d/, /\d/],
    2: [rusLetters, rusLetters, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    3: [/\d/, /\d/, /\d/, engLetters, /\d/, /\d/, /\d/, /\d/, /\d/],
    // 4: [/.+/],
    5: [rusLetters, rusLetters, /\d/, /\d/, /\d/, rusLetters, /\d/, /\d/, /\d/],
    6: [rusLetters, rusLetters, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    7: [rusLetters, rusLetters, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  }
  const placeholders = {
    0: "А000АА000",
    1: "0000АА000",
    2: "АА0000000",
    3: "000D00000",
    // 4: "",
    5: "АА000А000",
    6: "АА000000",
    7: "АА000000"
  }

  return (
    <Fragment>
      <br />
      {props.values.insurance_kind.id == 9 && (
        <RadioButtonGroup label="Риск" name="contract_auto.risk.value">
          {contractAutoRisksOptions.map(({ label, value }) => (
            <RadioInput key={`contract_auto_risk_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
      )}
      <SelectInput
        name="contract_auto.car_brand.value"
        label="Марка"
        options={carBrandOptions}
        isSearchable
        onChange={(e) => {
          props.setFieldValue("contract_auto.car_brand.value", e.value)
          props.setFieldValue("contract_auto.car_model.value", null)
          carModelStore.fetch({ limit: 0, filter: { by_car_brand_id: e.value } })
        }}
        isDisabled={
          (props.values.has_additional && getIn(props.values, "contract_auto.car_brand.value")) || prolongation
        }
        required={props.values.status.value.toString() === "1"}
      />
      <SelectInput
        name="contract_auto.car_model.value"
        label="Модель"
        options={carModelOptions}
        isSearchable
        isDisabled={
          !props.values.contract_auto.car_brand.value ||
          (props.values.has_additional && getIn(props.values, "contract_auto.car_model.value")) ||
          prolongation
        }
        required={props.values.status.value.toString() === "1"}
      />
      <RadioButtonGroup label="Вид гос. номера" name="contract_auto.number_kind.value">
        {contractAutoNumberKindsOptions.map(({ label, value }) => (
          <RadioInput key={`contract_auto_number_kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {props.values.contract_auto.number_kind.value.toString() == "4" && (
        <TextInput name="contract_auto.number" label="Гос. номер" />
      )}
      {props.values.contract_auto.number_kind.value.toString() != "4" && (
        <MaskedInput
          name="contract_auto.number"
          label="Гос. номер"
          mask={masks[props.values.contract_auto.number_kind.value]}
          placeholder={placeholders[props.values.contract_auto.number_kind.value]}
          pipe={(conformedValue) => conformedValue.toUpperCase()}
        />
      )}
    </Fragment>
  )
}

export default observer(AutoTab)
