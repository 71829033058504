import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"

const Bill = types.model("BudgetNor", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  index: types.maybeNull(types.string),
  second_level_bill_name: types.maybeNull(types.string)
})

const BudgetNorItem = types.model("BudgetNorItem", {
  id: types.maybeNull(types.integer),
  month: types.maybeNull(types.number),
  cost: types.maybeNull(types.string)
})

const BudgetNor = types.model("BudgetNor", {
  id: types.maybeNull(types.integer),
  budget_id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  bill: types.maybeNull(Bill),
  comment: types.maybeNull(types.string),
  budget_nor_items: types.maybeNull(types.array(BudgetNorItem))
})

export { BudgetNor }
