import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { ContractInstallment } from "./ContractInstallment"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"
import toOptions from "utils/newToOptions"
import format from "date-fns/format"

const Filter = types.model("Filter", {
  by_channel: types.maybeNull(customEnum),
  by_user: types.maybeNull(customEnum),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_insurer: types.maybeNull(customEnum),
  by_client: types.maybeNull(customEnum),
  by_subdivision: types.maybeNull(customEnum),
  by_insurer: types.maybeNull(customEnum),
  by_insurance_kind: types.maybeNull(customEnum),
  by_payment_on_start: types.maybeNull(customDate),
  by_payment_on_finish: types.maybeNull(customDate),
  by_payment_control_on_from: types.maybeNull(customDate),
  by_payment_control_on_to: types.maybeNull(customDate)
})

const FilterOptions = types.model("FilterOptions", {
  sp_payment_kinds: types.array(newCustomEnum, []),
  sp_sinergy_payment_forms: types.array(newCustomEnum, []),
  sp_sk_payment_forms: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
  statistics: types.maybeNull(types.frozen())
})

const Insurer = types.model("Insurer", {
  id: types.integer,
  name: types.string
})

const Client = types.model("Client", {
  id: types.integer,
  name: types.string
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.integer,
  name: types.string
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  insurer: types.maybeNull(Insurer),
  client: types.maybeNull(Client),
  insurance_kind: types.maybeNull(InsuranceKind),
  number: types.maybeNull(types.string),
  tmp_payment_cost: types.maybeNull(types.string),
  tmp_payment_on: types.maybeNull(customDate),
  until_to_payment: types.maybeNull(types.integer),
  kvs: types.array(types.frozen()),
  fix_kv_cost: types.maybeNull(types.string)
})

const metaDefaultValue = {
  filter: {
    by_number: null,
    by_user: { id: null, name: null },
    by_client: { id: null, name: null },
    by_insurance_kind: { id: null, name: null },
    by_subdivision: { id: null, name: null },
    by_insurer: { id: null, name: null },
    by_payment_on_start: null,
    by_payment_on_finish: null,
    by_channel_id: null,
    by_insurer_id: null
  },
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  },
  statistics: []
}

const ContractInstallmentStore = types
  .model("ContractInstallmentStore", {
    data: types.array(Contract, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { sp_payment_kinds, sp_sinergy_payment_forms, sp_sk_payment_forms } = self.meta.filters_options
      return {
        sp_payment_kinds: toOptions(sp_payment_kinds),
        sp_sinergy_payment_forms: toOptions(sp_sinergy_payment_forms),
        sp_sk_payment_forms: toOptions(sp_sk_payment_forms)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      // params = {...params, filter: {...self.meta.filter, ...params.filter}}
      self.setState("pending")
      if (params.filter) {
        const { by_payment_control_on_from, by_payment_control_on_to } = params.filter
        if (by_payment_control_on_from) {
          params.filter = { ...params.filter, by_payment_control_on_from: format(by_payment_control_on_from, "yyyy-MM-dd") }
        }
        if (by_payment_control_on_to) {
          params.filter = { ...params.filter, by_payment_control_on_to: format(by_payment_control_on_to, "yyyy-MM-dd") }
        }
      }
      return instance
        .get("/contracts/report", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContractInstallmentStore
