import React, { Fragment, useState } from "react"
import classnames from "classnames"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { useDropzone } from "react-dropzone"

const FileInput = ({ name, label, type = "file", onChange = () => {}, accept, fileFormatHint = null, ...props }) => {
  const id = idGenerator()
  const { errors, values, setSubmitting } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })

  const getFileFromUrl = (url) => url && decodeURI(url.replace(/^.*[\\|/]/, ""))

  const [uploadProgress, setUploadProgress] = useState(0)

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: (acceptedFiles) => {
      setUploadProgress(10)

      acceptedFiles.map((acceptedFile) =>
        onChange({
          target: {
            files: [acceptedFile]
          }
        })
      )

      setUploadProgress(100)
      setUploadProgress(0)
    }
  })

  console.log(error)

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Перетащите сюда файл или кликните, чтобы выбрать файл</p>
        {fileFormatHint && <em>{fileFormatHint}</em>}
      </div>
      {uploadProgress > 0 && uploadProgress <= 100 && (
        <div className="progress" style={{ marginTop: "0.5rem" }}>
          <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }} />
        </div>
      )}

      {value.map((attachment) => (
        <Fragment>
          <span style={{ display: "inline-block", marginTop: "0.5rem" }}>
            <a href={attachment.file.url} target="_blank" rel="noopener noreferrer">
              {getFileFromUrl(attachment.file.url)}
            </a>
            <button type="button" className="close" style={{marginLeft: '6px', marginTop: "-2px"}} onClick={() => props.onRemove(attachment.id)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
          <br />
        </Fragment>
      ))}

      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default connect(FileInput)
