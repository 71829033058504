import React, { Fragment, useEffect, useState, useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { AsyncSelectInput, DateInput, TextInput, Button, SelectInput } from "components/UI"
import ChannelStore from "stores/ChannelStore/List"
import ClientSelect from "components/Common/ClientSelect"
import UserSelect from "components/Common/UserSelect"
import SubagentSelect from "components/Common/SubagentSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"
import TagSelect from "components/Common/TagSelect"
import HoldingSelect from "components/Common/HoldingSelect"
import classnames from "classnames"
import Accordion from "react-bootstrap/Accordion"
import BCard from "react-bootstrap/Card"
import { connect, getIn, ErrorMessage, FieldArray } from "formik"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"

const FilterItem = ({ arrayHelpers, property, index, prefix = null }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { data: currentUser } = useContext(AuthStoreContext)

  return (
    <BCard>
      <Accordion.Toggle
        style={{
          background: Object.keys(property).length !== 0 ? "#c3e6cb" : "#f5c6cb"
        }}
        as={BCard.Header}
        eventKey={index.toString()}
      >
        Отчет {index + 1}.
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index.toString()}>
        <div class="card-body">
          <div className="row">
            <div className="col-3">
              <InsurerSelect name={path("by_insurer")} isMulti />
            </div>
            <div className="col-3">
              <ChannelSelect name={path("by_channel")} isMulti />
            </div>
            <div className="col-3">
              <DateInput name={path("by_conclusion_on_from")} label="Дата договора с" />
            </div>
            <div className="col-3">
              <DateInput name={path("by_conclusion_on_to")} label="Дата договора по" />
            </div>
            <div className="col-3">
              <ClientSelect name={path("by_client.id")} isMulti />
            </div>
            <div className="col-3">
              <TextInput name={path("by_number")} label="№ договора" />
            </div>
            <div className="col-3">
              <DateInput name={path("by_period_from")} label="Период отчета с" />
            </div>
            <div className="col-3">
              <DateInput name={path("by_period_to")} label="Период отчета по" />
            </div>
            <div className="col-3">
              <UserSelect name={path("by_user.id")} label="Исполнитель" isMulti />
            </div>
            <div className="col-3">
              {([7, 8].includes(currentUser.subdivision.id) && currentUser.id != 62) ||
              currentUser.id.toString() == "48" ? (
                <SubdivisionSelect name={path("by_subdivision.id")} isMulti />
              ) : [7, 29].includes(currentUser.id) ? (
                <SubdivisionSelect
                  name={path("by_subdivision.id")}
                  isMulti
                  isOptionDisabled={(option) => !(currentUser.id === 7 ? [2, 4, 10] : [2, 4]).includes(option.value)}
                />
              ) : [10].includes(currentUser.id) ? (
                <SubdivisionSelect
                  name={path("by_subdivision.id")}
                  isMulti
                  isOptionDisabled={(option) => ![4, 5].includes(option.value)}
                />
              ) : [62].includes(currentUser.id) ? (
                <SubdivisionSelect
                  name={path("by_subdivision.id")}
                  isMulti
                  isOptionDisabled={(option) => ![1, 2, 5, 7, 9, 11].includes(option.value)}
                />
              ) : (
                <SubdivisionSelect
                  name="by_subdivision_id"
                  value={{ label: currentUser.subdivision.name, value: currentUser.subdivision.id }}
                  isDisabled
                />
              )}
            </div>
            <div className="col-3">
              <InsuranceKindSelect name={path("by_insurance_kind")} isMulti />
            </div>
            <div className="col-3">
              <SubagentSelect name={path("by_subagent.id")} isMulti />
            </div>
            <div className="col-3">
              <TagSelect name={path("by_tag")} label="Теги" isMulti />
            </div>
            <div className="col-3">
              <TextInput name={path("by_customer_inviter")} label="Лицо, пригласившее Клиента" />
            </div>
            <div className="col-3">
              <SelectInput
                name={path("by_client_kind")}
                label="Тип страхователя (ЮЛ/ФЛ)"
                options={[
                  {
                    label: "Физическое лицо",
                    value: "0"
                  },
                  {
                    label: "Юридическое лицо",
                    value: "1"
                  }
                ]}
              />
            </div>
            <div className="col-3">
              <HoldingSelect name={path("by_holding.id")} isMulti />
            </div>
            <div className="col-12">
              {index > 0 && (
                <Button variant="danger" onClick={() => arrayHelpers.remove(index)}>
                  Удалить
                </Button>
              )}
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </BCard>
  )
}

const FilterItems = ({ values }) => {
  return (
    <FieldArray
      name="properties"
      render={(arrayHelpers) => (
        <Fragment>
          <Accordion defaultActiveKey="0">
            {values.properties.map((property, index) => (
              <FilterItem
                arrayHelpers={arrayHelpers}
                property={property}
                index={index}
                prefix={`properties.${index}`}
              />
            ))}
          </Accordion>
          <br />
          <Button variant="success" onClick={() => arrayHelpers.push({})}>
            Добавить
          </Button>
        </Fragment>
      )}
    />
  )
}

export default FilterItems
