import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Operation } from "./Operation"
import toOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import customDate from "types/customDate"
import format from "date-fns/format"
import parseMoney from "utils/parseMoney"

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  kinds: types.array(newCustomEnum, []),
  forms: types.array(newCustomEnum, []),
  operation_kinds: types.array(newCustomEnum, []),
  cash_kinds: types.array(newCustomEnum, []),
  moving_kinds: types.array(newCustomEnum, [])
})

const Filter = types.model("Filter", {
  by_date_on_from: types.maybeNull(customDate),
  by_date_on_to: types.maybeNull(customDate),
  by_subdivision_ids: types.maybeNull(types.frozen()),
  by_bills: types.maybeNull(types.frozen()),
  by_counterparts: types.maybeNull(types.frozen()),
  by_comment: types.maybeNull(types.union(types.integer, types.string)),
  by_status: types.maybeNull(types.integer),
  by_cost: types.maybeNull(types.frozen()),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_cash_kind: types.maybeNull(types.integer),
  by_form: types.maybeNull(types.integer),
  by_currency: types.maybeNull(types.integer),
  by_kind: types.maybeNull(types.integer),
  by_operation_kind: types.maybeNull(types.integer),
  by_channels: types.maybeNull(types.frozen()),
  by_creators: types.maybeNull(types.frozen()),
  by_users: types.maybeNull(types.frozen()),
  by_prepayment: types.maybeNull(types.integer),
  by_project: types.maybeNull(customEnum),
  by_projects: types.maybeNull(types.frozen()),
  by_payment_on_from: types.maybeNull(customDate),
  by_payment_on_to: types.maybeNull(customDate)
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
  statistics: types.maybeNull(types.frozen())
})

const metaDefaultValue = {
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  },
  filters_options: {
    statuses: [],
    kinds: [],
    forms: [],
    operation_kinds: [],
    cash_kinds: [],
    moving_kinds: []
  },
  statistics: []
}

const OperationStore = types
  .model("Operation", {
    data: types.array(Operation, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get operationOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      })
    },

    get filtersOptions() {
      const { statuses, kinds, forms, operation_kinds, cash_kinds, moving_kinds } = self.meta.filters_options
      return {
        statuses: toOptions(statuses),
        kinds: toOptions(kinds),
        forms: toOptions(forms),
        operation_kinds: toOptions(operation_kinds),
        cash_kinds: toOptions(cash_kinds),
        moving_kinds: toOptions(moving_kinds)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_date_on_from, by_date_on_to, by_cost } = params.filter
        if (by_date_on_from) {
          params.filter = { ...params.filter, by_date_on_from: format(by_date_on_from, "yyyy-MM-dd") }
        }
        if (by_date_on_to) {
          params.filter = { ...params.filter, by_date_on_to: format(by_date_on_to, "yyyy-MM-dd") }
        }
        if (by_cost) {
          params.filter = { ...params.filter, by_cost: parseMoney(by_cost) }
        }
      }

      return instance
        .get("/operations", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetch_placements(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_date_on_from, by_date_on_to, by_cost } = params.filter
        if (by_date_on_from) {
          params.filter = { ...params.filter, by_date_on_from: format(by_date_on_from, "yyyy-MM-dd") }
        }
        if (by_date_on_to) {
          params.filter = { ...params.filter, by_date_on_to: format(by_date_on_to, "yyyy-MM-dd") }
        }
        if (by_cost) {
          params.filter = { ...params.filter, by_cost: parseMoney(by_cost) }
        }
      }

      return instance
        .get("/operations/placements", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/operations/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default OperationStore
