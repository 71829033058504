import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetFotsStore from "stores/BudgetFotStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/BudgetFots/components/Tabs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"
import sortBy from "lodash.sortby"

const budgetStore = BudgetStore.create()
const budgetFotStore = BudgetFotsStore.create()

const calculate = (item) =>
  parseMoney(item.fot) < parseMoney(budgetStore.data.coefficient_mrot)
    ? parseMoney(item.fot) * (budgetStore.data.coefficient_fot_one / 100 + budgetStore.data.coefficient_fot_two / 100)
    : parseMoney(budgetStore.data.coefficient_mrot) * (budgetStore.data.coefficient_fot_one / 100) +
      ((parseMoney(item.fot) - parseMoney(budgetStore.data.coefficient_mrot)) * budgetStore.data.coefficient_fot_two) /
        100 +
      (parseMoney(item.fot) * budgetStore.data.coefficient_fot_three) / 100

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetFotStore.fetchAll({ budget_id })
  }, [])

  const [fact, setFact] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_fots/nalog_fact`).then(({ data }) => {
      setFact(data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>ФОТ</BreadcrumbItem>
        <BreadcrumbItem active>Налоги ФОТ</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead>
            <tr>
              <th colSpan={14}>План Налоги ФОТ</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={2}>ФИО</th>
            </tr>
            <tr>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>Итого</th>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                <th>
                  {formatMoney(budgetFotStore.data.reduce((a, b) => a + calculate(b.budget_fot_items[month]), 0))}
                </th>
              ))}
            </tr>
          </thead>
          {budgetFotStore.data.length > 0 && (
            <tbody>
              {sortBy(budgetFotStore.data, (i) => i.user.name).map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.user.name}</td>
                  {values.budget_fot_items.map((item, itemIndex) => (
                    <td key={`item=${itemIndex}`}>{formatMoney(calculate(item))}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          <thead>
            <tr>
              <th colSpan={14}>Факт Налоги ФОТ</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={2}>ФИО</th>
            </tr>
            <tr>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>Итого</th>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                <th>{formatMoney(fact.reduce((a, b) => a + parseMoney(b.costs[month]), 0))}</th>
              ))}
            </tr>
          </thead>
          {fact.length > 0 && (
            <tbody>
              {fact.map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.name}</td>
                  {values.costs.map((cost) => (
                    <td>{formatMoney(cost)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  )
}

export default observer(List)
