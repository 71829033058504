import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const status = (days) => {
  switch (true) {
    case days < 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Просрочено" />
    case days >= 0 && days <= 7:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Срок подходит" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="В плане" />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Страховая компания</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">№ договора</th>
        <th scope="col">СП к оплате</th>
        <th scope="col">КВ по нач. СП</th>
        <th scope="col">Конт. дата оплаты</th>
        <th scope="col">Дней до оплаты</th>
        <th scope="col">Статус оплаты</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`contract-installment-${index}`}>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.id}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.insurer.name}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.client.name}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.insurance_kind.name}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.number}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.tmp_payment_cost}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>
            {formatMoney(parseMoney(row.tmp_payment_cost) * row.kvs.reduce((a, b) => a + parseMoney(b.rate), 0) * 0.01 + parseMoney(row.fix_kv_cost))}
          </td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{formatDate(row.tmp_payment_on)}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.until_to_payment}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{status(row.until_to_payment)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
