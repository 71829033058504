import React, { Fragment, useContext, useEffect } from "react"
import { observer } from "mobx-react"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import CounterpartSelect from "components/Common/CounterpartSelect"
import { SelectInput, CheckboxInput, CurrencyInput, PercentageInput, RadioButtonGroup, RadioInput, TextInput, DateInput } from "components/UI"
import AuthStoreContext from "contexts/AuthStoreContext"
import UserSelect from "components/Common/UserSelect"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import BillSelect from "components/Common/BillSelect"
import RelationContract from "components/Common/RelationContract"
import ProjectSelect from "components/Common/ProjectSelect"
import { getMonth, getYear } from "date-fns"
import DateOnInput from "./DateOnInput"
import RefundOnInput from "./RefundOnInput"
import StandardPeriods from "./StandardPeriods"

const billSelectForm = (values) => {
  if (["0", "1"].includes(values.kind.value.toString()) && values.operation_kind.value.toString() == 0) {
    return 1
  }
  return null
}

const InfoTab = ({ store, values, setFieldValue }) => {
  const { statuses, kinds, operation_kinds, forms, moving_kinds } = store.filtersOptions
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    if (values.operation_kind.value.toString() == "1") {
      setFieldValue("bill", { id: null })
    }
  }, [values.operation_kind.value])
  return (
    <Fragment>
      <RadioButtonGroup label="Статус операции" name="status.value">
        <RadioInput key="status_0" label="Черновик" id={0} />
        <RadioInput key="status_1" label="Выпущен" id={1} />
        <RadioInput key="status_5" label="План" id={5} />
        <RadioInput key="status_2" label="Ожидание" id={2} disabled />
        <RadioInput key="status_3" label="Отказ" id={3} disabled />
        <RadioInput key="status_4" label="Ошибка" id={4} />
      </RadioButtonGroup>
      <RadioButtonGroup label="Тип операции" name="kind.value">
        {kinds.map(({ label, value }) => (
          <RadioInput
            key={`kind_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              setFieldValue("kind.value", e.target.value)
              switch (e.target.value.toString()) {
                case "2":
                  setFieldValue("cashier_from", { id: 5, name: "Иванов Павел" })
                  setFieldValue("cashier_to", { id: 49, name: "Штыркова Виктория" })
                  setFieldValue("comment", "Перевод средств между счетами")

                  if (currentUser.subdivision.id.toString() === "7" || currentUser.id.toString() == "48") {
                    setFieldValue("moving_kind.value", 1)
                    setFieldValue("cashier_from", { id: 49, name: "Штыркова Виктория" })
                    setFieldValue("cashier_to", { id: 5, name: "Иванов Павел" })
                  }
                  break
                case "3":
                  setFieldValue("operation_kind", { value: 0 })
                  setFieldValue("form", { value: 1 })
                  setFieldValue("bill", { id: 120, name: "ДЕПОЗИТ РАЙФ" })
                  setFieldValue("counterpart", { id: 1111, name: "Райффайзенбанк" })
                  setFieldValue("rate", "2.000")
                  setFieldValue("comment", "Размещение депозита")
                  break
              }
            }}
          />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Вид операции" name="operation_kind.value">
        {operation_kinds.map(({ label, value }) => (
          <RadioInput
            key={`operation_kind_${value}`}
            label={label}
            id={value}
            disabled={values.kind.value.toString() === "3" && value == "1"}
            onChange={(e) => {
              setFieldValue("operation_kind.value", e.target.value)
              if (e.target.value.toString() === "1") {
                setFieldValue("bill", { id: null, name: null })
              }
            }}
          />
        ))}
      </RadioButtonGroup>
      {["2", "3"].includes(values.kind.value.toString()) && (
        <RadioButtonGroup label="Форма расчета" name="form.value">
          {forms.map(({ label, value }) => (
            <RadioInput key={`form_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
      )}
      {values.kind.value.toString() !== "2" && values.operation_kind.value.toString() !== "1" && (
        <BillSelect
          name="bill"
          label={
            ["0", "1"].includes(values.kind.value.toString()) && values.operation_kind.value.toString() == 0
              ? "P&L Счет"
              : "Счет"
          }
          required
          form={
            ["0", "1"].includes(values.kind.value.toString()) && values.operation_kind.value.toString() == 0 ? 1 : null
          }
        />
      )}

      {values.kind.value.toString() === "3" && (
        <BillSelect name="bill" label="P&L Счет" required ids={[25, 26, 27, 28, 29, 119, 120, 121]} />
      )}

      {values.kind.value.toString() === "2" && (
        <RadioButtonGroup label="Тип перемещения" name="moving_kind.value">
          {moving_kinds.map(({ label, value }) => (
            <RadioInput
              key={`moving_kind_${value}`}
              label={label}
              id={value}
              onChange={(e) => {
                setFieldValue("moving_kind.value", e.target.value)
                switch (e.target.value.toString()) {
                  case "0":
                    setFieldValue("cashier_from", { id: 5, name: "Иванов Павел" })
                    setFieldValue("cashier_to", { id: 49, name: "Штыркова Виктория" })
                    break
                  case "1":
                    setFieldValue("cashier_from", { id: 49, name: "Штыркова Виктория" })
                    setFieldValue("cashier_to", { id: 5, name: "Иванов Павел" })
                    break
                  case "2":
                    setFieldValue("cashier_from", { id: 49, name: "Штыркова Виктория" })
                    setFieldValue("bank", "РАЙФФАЙЗЕН")
                    break
                  case "3":
                    setFieldValue("cashier_from", { id: 5, name: "Иванов Павел" })
                    setFieldValue("bank", "РАЙФФАЙЗЕН")
                    break
                }
              }}
            />
          ))}
        </RadioButtonGroup>
      )}
      {values.kind.value.toString() === "2" && values.operation_kind.value.toString() == "1" && (
        <RelationContract name="relation_contract" />
      )}

      {values.kind.value.toString() !== "2" &&
        values.kind.value.toString() !== "3" &&
        values.operation_kind.value.toString() !== "1" && <SubdivisionSelect name="subdivision.id" />}
      {values.kind.value.toString() !== "3" &&
        (values.kind.value.toString() !== "2" || values.operation_kind.value.toString() !== "1") && (
          <CounterpartSelect required />
        )}
      {values.kind.value.toString() === "3" && (
        <CounterpartSelect
          required
          afterChange={(_, value) => {
            if (value === 1111 && values.kind.value.toString() === "3") {
              setFieldValue("rate", "2.000")
              setFieldValue("comment", "Размещение депозита")
            }
          }}
        />
      )}
      {values.kind.value.toString() === "3" && (
        <Fragment>
          <ChannelSelect label="Юр.лицо" required />
        </Fragment>
      )}
      <DateOnInput required />
      {values.kind.value.toString() === "3" && (
        <Fragment>
          <div className="form-group">
            <StandardPeriods />
          </div>
          <RefundOnInput required />
          <div className="form-group">
            <CheckboxInput name="is_prolongation" label="Автопролонгация" />
            <br />
          </div>
          <TextInput required name="duration" label="Срок, дней" readOnly />
        </Fragment>
      )}
      <DateInput
        name="date_on"
        label="Дата операции"
        required
        onChange={(e) => {
          setFieldValue("date_on", e)
          if (values.bill.index !== "80320") {
            const month = getMonth(e) + 1
            const year = getYear(e)
            setFieldValue("month", month.toString())
            setFieldValue("year", year.toString())
          }
        }}
      />
      <CurrencyInput name="cost" label="Сумма, руб." required />
      <CurrencyInput name="cost" label="Сумма" required />
      {values.kind.value.toString() === "3" && (
        <Fragment>
          <RadioButtonGroup label="Валюта" name="currency.value">
            {[
              { value: 0, label: "RUR" },
              { value: 1, label: "USD" },
              { value: 2, label: "EUR" }
            ].map(({ label, value }) => (
              <RadioInput key={`cargo_currency_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <PercentageInput name="rate" label="Ставка, %" />
        </Fragment>
      )}
      <hr />
      {["1"].includes(values.operation_kind.value.toString()) && values.operation_kind.value.toString() !== "1" && (
        <Fragment>
          <ChannelSelect label="Юр.лицо, по которому проходит операция" />
        </Fragment>
      )}

      {values.kind.value.toString() !== "2" && (
        <TextInput name="comment" label="Назначение операции/Комментарий" required />
      )}

      {values.kind.value.toString() === "2" && (
        <Fragment>
          {["0", "1"].includes(values.moving_kind.value.toString()) && (
            <UserSelect name="cashier_to.id" label="Кассир принимающей стороны " required />
          )}
          {["2", "3"].includes(values.moving_kind.value.toString()) && (
            <Fragment>
              <ChannelSelect label="Юр.лицо, по которому проходит операция" />
              <TextInput name="bank" label="Банк" required />
              <TextInput name="comment" label="Назначение операции/Комментарий" required />
            </Fragment>
          )}
          <UserSelect name="cashier_from.id" label="Кассир передающей стороны" required />
        </Fragment>
      )}
      {values.bill &&
        values.bill.index &&
        (values.bill.index.startsWith("80") ||
          values.bill.index.startsWith("701") ||
          values.bill.index.startsWith("904") ||
          values.bill.index.startsWith("905") ||
          values.bill.index.startsWith("906")) && (
          <Fragment>
            <hr />
            <h6>Период отчетности</h6>
            <div className="row">
              <div className="col-4">
                <SelectInput
                  name="month"
                  label="Месяц"
                  options={[
                    { label: "Январь", value: "1" },
                    { label: "Февраль", value: "2" },
                    { label: "Март", value: "3" },
                    { label: "Апрель", value: "4" },
                    { label: "Май", value: "5" },
                    { label: "Июнь", value: "6" },
                    { label: "Июль", value: "7" },
                    { label: "Август", value: "8" },
                    { label: "Сентябрь", value: "9" },
                    { label: "Октябрь", value: "10" },
                    { label: "Ноябрь", value: "11" },
                    { label: "Декабрь", value: "12" }
                  ]}
                  isClearable
                  required={
                    values.bill &&
                    values.bill.index &&
                    ((values.bill.index.startsWith("80") && !["80320", "80330"].includes(values.bill.index)) ||
                      values.bill.index === "80310" ||
                      values.bill.index.startsWith("904"))
                  }
                />
              </div>
              <div className="col-4">
                <SelectInput
                  name="quarter"
                  label="Квартал"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" }
                  ]}
                  isClearable
                  required={values.bill && values.bill.index && values.bill.index === "80320"}
                  disabled
                />
              </div>
              <div className="col-4">
                <SelectInput
                  name="year"
                  label="Год"
                  options={[
                    { label: "2020", value: "2020" },
                    { label: "2021", value: "2021" },
                    { label: "2022", value: "2022" },
                    { label: "2023", value: "2023" },
                    { label: "2024", value: "2024" },
                    { label: "2025", value: "2025" },
                    { label: "2026", value: "2026" },
                    { label: "2027", value: "2027" },
                    { label: "2028", value: "2028" },
                    { label: "2029", value: "2029" },
                    { label: "2030", value: "2030" }
                  ]}
                  isClearable
                  required
                />
              </div>
            </div>
          </Fragment>
        )}
      <ProjectSelect required />
      {values.kind.value.toString() === "3" && (
        <UserSelect name="user.id" label="Кассир" required isDisabled={currentUser.id !== 5} setCurrentUser />
      )}
    </Fragment>
  )
}

export default observer(InfoTab)
