import React, { Fragment, useState } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { Button, ResetButton, SelectInput, Submit, TextInput, DateInput, CurrencyInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import classnames from "classnames"

import CounterpartSelect from "components/Common/CounterpartSelect"
import SubdivisionSelect from "./components/SubdivisionSelect"
import BillSelect from "components/Common/BillSelect"
import ChannelSelect from "components/Common/ChannelSelect"
import UserSelect from "components/Common/UserSelect"
import ProjectSelect from "components/Common/ProjectSelect"

const Filter = ({ store, handleSubmit }) => {
  const { kinds, operation_kinds, statuses, cash_kinds, forms } = store.filtersOptions
  const [isShowAdditional, setIsShowAdditional] = useState(false)
  const toggleAddtional = () => setIsShowAdditional(!isShowAdditional)
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <BillSelect name="by_bills" isMulti />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_subdivision_ids" isMulti />
        </div>
        <div className="col-3">
          <CounterpartSelect name="by_counterparts" isMulti />
        </div>
        <div className="col-3">
          <TextInput name="by_comment" label="Назначение платежа" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_from" label="Дата с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_to" label="Дата по" />
        </div>
        <div className="col-3">
          <SelectInput name="by_operation_kind" label="Вид операции" options={operation_kinds} isClearable />
        </div>
        <div className="col-3">
          <SelectInput name="by_kind" label="Тип операции" options={kinds} isClearable />
        </div>

        {isShowAdditional && (
          <Fragment>
            <div className="col-3">
              <CurrencyInput name="by_cost" label="Сумма" />
            </div>
            <div className="col-3">
              <SelectInput name="by_status" label="Статус" options={statuses} isClearable />
            </div>
            <div className="col-3">
              <ChannelSelect label="Юр.лицо расчетов" name="by_channels" isMulti />
            </div>
            <div className="col-3">
              <TextInput name="by_number" label="Номер договра/АВР" />
            </div>
            <div className="col-3">
              <SelectInput name="by_form" label="Форма расчета" options={forms} isClearable />
            </div>
            <div className="col-3">
              <SelectInput name="by_cash_kind" label="Вид кассы" options={cash_kinds} isClearable />
            </div>
            <div className="col-3">
              <UserSelect name="by_creators.id" label="Создатель" isMulti />
            </div>
            <div className="col-3">
              <UserSelect name="by_users.id" label="Кассир" isMulti />
            </div>
            <div className="col-3">
              <SelectInput
                name="by_prepayment"
                label="Аванс. отчет"
                options={[
                  {
                    label: <i className="fas fa-xmark" style={{ color: "red" }}></i>,
                    value: "0"
                  },
                  {
                    label: <i className="fas fa-check" style={{ color: "green" }}></i>,
                    value: "1"
                  },
                  {
                    label: <i className="fas fa-stop" style={{ color: "red" }}></i>,
                    value: "2"
                  }
                ]}
                isClearable
              />
            </div>
            <div className="col-3">
              <ProjectSelect name="by_projects.id" isMulti />
            </div>
            <div className="col-3">
              <DateInput name="by_payment_on_from" label="Период отчета с" />
            </div>
            <div className="col-3">
              <DateInput name="by_payment_on_to" label="Период отчета по" />
            </div>
          </Fragment>
        )}
      </div>
      <Button onClick={toggleAddtional} title="Дополнительные фильтры">
        <i className={classnames(["fas", { "fa-angle-up": isShowAdditional, "fa-angle-down": !isShowAdditional }])}></i>
      </Button>
      &nbsp;
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      {store.meta.statistics.map((i) => (
        <Fragment key={`statustic-${i}`}>
          &nbsp;&nbsp;&nbsp;&nbsp;{i[0]}: <b>{i[1]}</b>
        </Fragment>
      ))}
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => {
      formikBag.props.resetSelected()
      filterSubmitHandler(values, formikBag)
    }
  })
)(Filter)
