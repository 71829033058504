import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetFotsStore from "stores/BudgetFotStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import Table from "./components/Table"
import TabsBlock from "pages/Budgets/containers/BudgetFots/components/Tabs"

const budgetStore = BudgetStore.create()
const budgetFotStore = BudgetFotsStore.create()

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetFotStore.fetch({ budget_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem active>ФОТ</BreadcrumbItem>
        <BreadcrumbItem active>Реестр</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <Card>
        <Button url={`/budgets/${budget_id}/budget_fots/new`}>Создать</Button>
      </Card>
      <Table store={budgetFotStore} />
      <Paginate store={budgetFotStore} />
    </Fragment>
  )
}

export default observer(List)
