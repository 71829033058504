import React, { Fragment } from "react"
import { TextInput, RadioButtonGroup, RadioInput, Button, Submit, CurrencyInput, SelectInput } from "components/UI"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import formSubmitHandler from "utils/formSubmitHandler"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import UserSelect from "components/Common/UserSelect"

const Form = ({ store, values, budgetStore, handleSubmit, ...props }) => {
  const { statuses, bills } = store.filtersOptions
  const fot = values.budget_fot_items.reduce((a, b) => a + (parseMoney(b.fot) * 0.87 + parseMoney(b.o_two)), 0)

  const copyJanuary = () => {
    values.budget_fot_items.slice(1).forEach((_, index) => {
      props.setFieldValue(`budget_fot_items.${index + 1}.fot`, values.budget_fot_items[0].fot)
      props.setFieldValue(`budget_fot_items.${index + 1}.o_two`, values.budget_fot_items[0].o_two)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Статус" name="status.value">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <UserSelect name="user.id" label="ФИО сотрудника" />

      <SelectInput name="bill.id" label="Статья бюджета" options={bills} isSearchable />
      <TextInput name="comment" label="Комментарий" />
      <p>
        Итого ФОТ {budgetStore.data.year}: {formatMoney(fot)}
      </p>
      <Button onClick={copyJanuary}>Скопировать январь</Button>
      <br />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "5300px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <tr>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                ЯНВ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                ФЕВ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                МАРТ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                АПР{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                МАЙ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                ИЮНЬ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                ИЮЛЬ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                АВГ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                СЕН{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                ОКТ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                НОЯ{budgetStore.data.year.toString().slice(2, 4)}
              </th>
              <th colSpan={3} style={{ border: "1px solid white" }}>
                ДЕК{budgetStore.data.year.toString().slice(2, 4)}
              </th>
            </tr>
            <tr>
              {values.budget_fot_items.map((item, itemIndex) => (
                <Fragment key={`item=${itemIndex}`}>
                  <th style={{ border: "1px solid white", width: "150px" }}>Actual NET</th>
                  <th style={{ border: "1px solid white", width: "150px" }}>ФОТ</th>
                  <th style={{ border: "1px solid white", width: "150px" }}>O2</th>
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {values.budget_fot_items.map((item, itemIndex) => (
                <Fragment key={`item=${itemIndex}`}>
                  <td>
                    {formatMoney(
                      parseMoney(values.budget_fot_items[itemIndex].fot) * 0.87 +
                        parseMoney(values.budget_fot_items[itemIndex].o_two)
                    )}
                  </td>
                  <td>
                    <CurrencyInput name={`budget_fot_items.${itemIndex}.fot`} bare style={{ width: "100%" }} />
                  </td>
                  <td>
                    <CurrencyInput name={`budget_fot_items.${itemIndex}.o_two`} bare style={{ width: "100%" }} />
                  </td>
                </Fragment>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: budget_id } = formikBag.props.budgetStore.data
      formSubmitHandler({ ...values, budget_id: budget_id }, formikBag, `/budgets/${budget_id}/budget_fots`)
    }
  }),
  observer
)(Form)
